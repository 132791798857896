import React from 'react';
import './App.css';

import DailyChart from './DailyChart/DailyChart';
import * as TallyApi from './Api/TallyApi';
import { useEffect, useState } from 'react';

function App() {

  const [data, setData] = useState();

  useEffect(() => {
    TallyApi.multi_csv(
      [
        process.env.PUBLIC_URL + '/data/TALLY-Activity-1-19-21.csv', 
        process.env.PUBLIC_URL + '/data/addendum.csv'
      ],
      'Episodes'
    )
      .then(data => {
        setData(data);
      });
  }, []);

  return (
    <div className="App">
      <header>
        <h1>
          Episodes
        </h1>
      </header>
      {data ? (
        <DailyChart data={data} />
      ) : (
        <header>Loading...</header>
      )}
    </div>
  );
}

export default App;
