// @flow

import React, { forwardRef } from 'react';

type Props = {|
  value: number;
  max: number;
|};

export const Bar = forwardRef((props: Props, ref) => {
  const widthPct = Math.min(1.0, props.value / props.max);
  const hsl = `hsl(208, 100%, ${96-(4*props.value)}%)`; // start at aliceblue
  return ( 
    <div ref={ref} className='bar' style={{ width: `${100 * widthPct}%`, background: hsl }}>
      <div className='bar-label'>
        {props.value}
      </div>
    </div>
  );
});

