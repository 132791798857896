// @flow

import { ProcessedData } from '../Api/Types.js';
import React, { Fragment, useState } from 'react';
import { Bar } from './Bar';
import { Reveal, Timeline, Tween } from 'react-gsap';

const MAX_DAY_COUNT = 10;
const MAX_WEEK_COUNT = 14;
const DAY_NAMES = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
const MONTH_NAMES = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

type Props = {|
  data: ProcessedData,
  days: Array<Date>,
  showDays?: boolean
|};

export default function Week(props: Props) {
  const [selected, setSelected] = useState(false);

  function handleWeekClick(event: SyntheticEvent<HTMLElement>) {
    setSelected(!selected);
  }

  function renderWeek(processed: ProcessedData, days: Array<Date>) {
    const notes = [];
    let weekCount = 0;

    for (var i in days) {
      const d = days[i];
      const key = d.toLocaleDateString();
      const dayData: RawData = processed.allDays[key];
      const count = dayData ? dayData.count : 0;
      const note = dayData ? dayData.note : undefined;
      weekCount += count;

      // Column 4 - notes
      if (note) {
        // TODO: could we just return the multiple-notes-per-day as an array?
        const lines = note.split('; ');
        lines.forEach((line, index) =>
          notes.push(
            <div className='note oneline'>
              {index === 0 && (
                <span style={{paddingRight: '.2em'}}>
                  {`${d.toLocaleDateString()}:`}
                </span>
              )}
              <span>
                {line}
              </span>
            </div>
          )
        );
      }
    }

    const alt = days[0].getMonth() % 2 ? 'alt' : '';

    return (
      <Fragment key={days[0].toLocaleDateString()}>
        <div className={`week clickable ${alt}`} onClick={handleWeekClick}>
          <div>Week of</div>
        </div>
        <div className={`days clickable ${alt}`} onClick={handleWeekClick}>
          <div className='DailyChart-label'>
            {`${MONTH_NAMES[days[0].getMonth()]} ${days[0].getDate()}`}
          </div>
        </div>
        <div className={`counts clickable ${alt}`} onClick={handleWeekClick}>
          <Reveal>
            <Tween from={{ width: 0 }} duration={.5} ease="back.out(1.0)">
              <Bar value={weekCount} max={Math.min(MAX_WEEK_COUNT, processed.maxWeekCount)} />
            </Tween>
          </Reveal>
        </div>
        <div className='notes'>
          {notes.length > 0 && 
            <div className='clickable' onClick={handleWeekClick} style={{display: 'flex'}}>
              {notes.length > 1 &&
                <Reveal>
                  <Tween from={{ scale: .25 }} duration={.5} ease="back(2.0)">
                    <span className='arrow-down'>{`+${notes.length}`}</span>
                  </Tween>
                </Reveal>
              }
              {notes[0]}
            </div>
          }
        </div>
      </Fragment>
    );
  }

  function renderWeekWithDays(processed: ProcessedData, days: Array<Date>) {
    const dayLabels = [];
    const counts = [];
    const notes = [];

    for (var i in days) {
      const day = days[i];
      const key = day.toLocaleDateString();
      const dayData: RawData = processed.allDays[key];
      const count = dayData ? dayData.count : 0;
      const note = dayData ? dayData.note : undefined;

      // Column 2 - name of each day
      const dayLabelText = `${MONTH_NAMES[day.getMonth()]} ${day.getDate()}`;
      dayLabels.push(
        <div key={i} className='DailyChart-label'>
          {dayLabelText}
        </div>
      );

      // Column 3 - bar chart + count
      counts.push(
        <Bar key={key} value={count} max={Math.max(MAX_DAY_COUNT, processed.maxDayCount)} />
      );

      // Column 4 - notes
      if (note) {
        // TODO: could we just return the multiple-notes-per-day as an array?
        const lines = note.split('; ');
        lines.forEach((line, index) =>
          notes.push(
            <div key={`${key}.${index}`} className='note'>
              {index === 0 && (
                <span>
                  {`${day.toLocaleDateString()}: `}
                </span>
              )}
              <span>
                {line}
              </span>
            </div>
          )
        );
      }
    }

    const alt = days[0].getMonth() % 2 ? 'alt' : '';

    return (
      <>
        <div className={`week clickable ${alt}`} onClick={handleWeekClick}>
          <div>{DAY_NAMES[days[0].getDay()]}</div>
        </div>
        <div className={`days clickable ${alt}`} onClick={handleWeekClick}>{dayLabels}</div>
        <div className={`counts clickable ${alt}`} onClick={handleWeekClick}>
          <Timeline target={counts}>
            <Tween from={{ width: 0, scaleY: 0.5 }} duration={.5} ease="back.out(1.0)" />
          </Timeline>
        </div>
        <div className='notes'>{notes}</div>
      </>
    );
  }

  return (
    <>
      {selected || props.showDays
      ?
        renderWeekWithDays(props.processed, props.days) :
        renderWeek(props.processed, props.days)}
    </>
  );
}
