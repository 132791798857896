// @flow

import { ProcessedData, RawData } from './Types.js'

const async_csv = require('async-csv');

export function graphql(url: ?string): Promise<ProcessedData> {
  var query = `query Episodes {
    healthcheck {
      now
    }
    episodes {
      last_updated
      foo
      all_days {
        date,
        count,
        note
      }
    }
  }`;

  return fetch(url || 'http://localhost:4000/graphql', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    body: JSON.stringify({
      query
    })
  })
    .then(r => r.json())
    .then(data => {
      return processRawData(data.data.episodes.all_days);
    });
}

export function csv(url: string, habit: string): Promise<ProcessedData> {
  return fetch(url, { method: 'GET', })
    .then(r => r.text())
    .then(data => parseCSV(data, habit))
    .then(data => processRawData(data));
}

export function multi_csv(urls: Array<string>, habit: string): Promise<ProcessedData> {
  return Promise.all(urls.map(url =>
    fetch(url, { method: 'GET', })
      .then(r => r.text())
      .then(data => parseCSV(data, habit))
  ))
    .then(allData => {
      return allData.flat();
    })
    .then(data => processRawData(data));
}

function parseCSV(csv: string, habitFilter: string): Promise<Array<RawData>> {
  // Expected format:
  //
  // HABIT,GROUP,DATE,DAY,TIME,VALUE,NOTE
  // Episodes,,2020-08-15,SAT,7:52:31 PM,1.0,

  return async_csv
    .parse(csv, { relax_column_count: true })
    .then(rows => {
      let byDay = [];
      rows.forEach(([habit, group, date, day, time, value, ...note]) => {
        note = note.join(",");
        if (habit === habitFilter) {
          const today = byDay[date] || { count: 0, note: undefined };
          if (value) {
            today.count = today.count + Math.floor(value);
          }
          if (note) {
            // Convert e.g. "7:14:02 PM" to "7:14 PM"
            const t = time.match(/([0-9]*):([0-9]*):[0-9]* ([A-Z]*)/);
            const shortTime = t.length === 4 ? `${t[1]}:${t[2]} ${t[3]} ` : ``;
            const prefix = today.note ? today.note + '; ' : '';
            today.note = prefix + shortTime + note.trim();
          }
          byDay[date] = today;
        }
      });

      const rawData: Array<RawData> = Object.keys(byDay).sort().map(key => {
        return {
          // TODO: Date() assumes UTC but this is still a big hack
          date: new Date(key.replace(/-/g, "/") + ' PST').toLocaleDateString(),
          count: byDay[key].count,
          note: byDay[key].note
        };
      });

      return rawData;
    });
}

// Assumes one row per day, format:
// { date: "", count: n, note: "" }
function processRawData(data: Array<RawData>): ProcessedData {
  // PreProcess. Find span of days and some sums.
  const processed = {
    totalCount: 0,
    maxDayCount: 0,
    maxWeekCount: 0,
    numberOfDays: 0,
    firstDay: undefined,
    lastDay: undefined,
    daySum: [], // size=7, one per day of week
    monthSum: [], // size=12, one per month of year
    allDays: new Map() // key=day in toLocalDateString() format, value=raw data value
  };

  data.forEach((datum, index) => {
    const ts = new Date(datum.date);
    if (!processed.firstDay || ts < processed.firstDay) {
      processed.firstDay = ts;
    }
    if (!processed.lastDay || ts > processed.lastDay) {
      processed.lastDay = ts;
    }
    if (!processed.maxDayCount || datum.count > processed.maxDayCount) {
      processed.maxDayCount = datum.count;
    }
    processed.totalCount += datum.count;
    processed.daySum[ts.getDay()] = (processed.daySum[ts.getDay()] || 0) + datum.count;
    processed.monthSum[ts.getMonth()] = (processed.monthSum[ts.getMonth()] || 0) + datum.count;

    processed.allDays[ts.toLocaleDateString()] = datum;
  });

  processed.numberOfDays = 1 + Math.floor((processed.lastDay.valueOf() - processed.firstDay.valueOf()) / (1000*60*60*24));

  var weekCount = 0;
  for (var d = new Date(processed.firstDay); d < processed.lastDay; d.setDate(d.getDate() + 1)) {
    if (d.getDay() === 0) {
      processed.maxWeekCount = Math.max(processed.maxWeekCount, weekCount);
      weekCount = 0;
    }
    const dayData = processed.allDays[d.toLocaleDateString()];
    weekCount += dayData ? dayData.count : 0;
  }
  processed.maxWeekCount = Math.max(processed.maxWeekCount, weekCount);

  return processed;
}
