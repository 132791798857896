// @flow

import './DailyChart.css';

import { ProcessedData } from '../Api/Types.js';
import React, { Fragment, useState } from 'react';
import Week from './Week';

type Props = {|
  data: ProcessedData
|};

export default function DailyChart(props: Props) {
  const [showDays, setShowDays] = useState(false);

  function iterateDaysIntoWeeks(processed: ProcessedData) {
    const rows = [];
    var days = [];
    var key = 0;

    for (var d = new Date(processed.firstDay); d <= processed.lastDay; d.setDate(d.getDate() + 1)) {
      // Insert year?
      if ((d.getMonth() === 0 && d.getDate() === 1) || d.valueOf() === processed.firstDay.valueOf()) {
        rows.push(
          <Fragment key={key++}>
            <div className='year'>
              {d.getFullYear()}
            </div>
            <div className='year'></div>
            <div className='year'></div>
            <div className='year'></div>
          </Fragment>
        );
      }

      days.push(new Date(d));

      // Change of week or year?
      if (d.getDay() === 6 || (d.getMonth() === 11 && d.getDate() === 31)) {
        rows.push(
          <Week key={key++} processed={processed} days={days} showDays={showDays} />
        );
        days = [];
      }
    }

    if (days.length > 0) {
      rows.push(
        <Week key={key++} processed={processed} days={days} showDays={showDays} />
      );
    }

    return rows;
  }

  function handleShowDays(event: ReactHTMLElement) {
    setShowDays(!showDays);
  }

  return (
    <div className='DailyChart'>
      <div>
        <button onClick={handleShowDays}>
          {`${showDays ? 'Show weeks' : 'Show days'}`}
        </button>
      </div>
      <div className='DailyChart-content'>
        {iterateDaysIntoWeeks(props.data)}
      </div>
    </div>
  );
}
